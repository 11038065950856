import {isBlank} from '@/util/objects'

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
    const valid_map = ['admin', 'editor']
    return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
    const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
    return reg.test(url)
}


/**
 * 手机号校验
 * @param {string} mobilePhone
 * @returns {Boolean}
 */
export function validMobilePhone(mobilePhone) {
    return /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(mobilePhone)
}

/**
 * 电话传真校验
 * @param phone
 * @returns {boolean}
 */
export function validPhone(phone) {
    return /^(\d{3,4}-)?\d{7,8}(-\d{1,6})?$/.test(phone)
}

/**
 * 银行卡号校验
 * @param card
 * @returns {boolean}
 */
export function validBankCard(card) {
    return /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/.test(card)
}

/**
 * 邮编校验
 * @param postalCode
 * @returns {boolean}
 */
export function validPostalCode(postalCode) {
    return /^[1-9]\d{5}$/.test(postalCode)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
    const reg = /^[a-z]+$/
    return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
    const reg = /^[A-Z]+$/
    return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
    const reg = /^[A-Za-z]+$/
    return reg.test(str)
}

/*
企业税号
 */
export function validShuiHao(str) {
    const reg = /^[0-9A-HJ-NPQRTUWXY]{15}$|^[0-9A-HJ-NPQRTUWXY]{18}$|^[0-9A-HJ-NPQRTUWXY]{20}$/
    return reg.test(str)
}

/**
 * 正则校验 正数 整数位 1-8位  小数位 0-2位
 * @param positiveFloat
 * @returns {boolean}
 */
function isPositiveEightTwo(positiveFloat) {
    return /^[1-9]\d{0,7}(\.\d{1,2})?$|^0(\.\d{1,2})?$/.test(positiveFloat);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return reg.test(email)
}

export function isDigits(number) {
    return /^\d{1,9}$/.test(number);
}

export function isInt_ZS_0(number) {
    return /^([1-9]\d*|[0]{1,1})$/.test(number) && number <= 2147483647;
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
    if (typeof str === 'string' || str instanceof String) {
        return true
    }
    return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
    if (typeof Array.isArray === 'undefined') {
        return Object.prototype.toString.call(arg) === '[object Array]'
    }
    return Array.isArray(arg)
}

export function isNumber(val) {
    if (val === "" || val == null) {
        return false;
    }
    return !isNaN(val);
}

function isValid(type, value) {
    if (type === 'number' && isNumber(value)) {
        return true;
    }
    if (type === 'array' && (!value || value.length === 0)) {
        return false;
    }
    return !!value;
}

/**
 * 正则校验 最多两位小数的百分数
 * @param percentage
 * @returns {boolean}
 */
function isPercentage(percentage) {
    return /^\d{1,3}$|^\d{1,3}\.\d{1,2}$/.test(percentage);
}


/**
 * 编号校验
 * @param percentage
 * @returns {boolean}
 */
function isBh(bh) {
    return /^[A-Za-z0-9]+$/.test(bh);
}

function isZmxhxsz(str){
    return /^[A-Za-z0-9_]+$/.test(str);
}
/**
 * 正则校验 整数 1-16位  小数 0-6位的 正数
 * @param positiveFloat
 * @returns {boolean}
 */
function isPositiveFloat(positiveFloat) {
    return /^\d{1,15}$|^\d{1,15}\.\d{1,6}$/.test(positiveFloat);
}

/**
 * 正则校验 整数 1-16位  小数 0-6位的 负数
 * @param negativeFloat
 * @returns {boolean}
 */
function isNegativeFloat(negativeFloat) {
    return /^-\d{1,15}$|^-\d{1,15}\.\d{1,6}$/.test(negativeFloat);
}

/**
 * 正则校验整数n，小数m 正数
 */
function isPositiveFloatNM(str, n = 22, m = 6) {
    let exp = new RegExp("^\\d{1," + n + "}$|^\\d{1," + n + "}\\.\\d{1," + m + "}$");
    return exp.test(str)
}

/**
 * 正则校验整数n，小数m 含负数
 */
function isArbitraryFloatNM(number, n = 22, m = 6) {
    let exp = new RegExp("^([-])?\\d{1," + n + "}$|^([-])?\\d{1," + n + "}\\.\\d{1," + m + "}$");
    return exp.test(number)
}

/**
 * 正则校验 正数  精度为2的小数  小数位 0-2位
 * @param positiveFloat
 * @returns {boolean}
 */
function isPositiveZeroTwo(positiveFloat) {
    return /^(0\.\d{1,2}|0?[.][1-9]\d?|0?[.][0-9][1-9]|1)$/.test(positiveFloat);
}

/**
 * 正则校验 整数 1-16位  小数 0-6位的 负数
 * @param arbitraryFloat
 * @returns {boolean}
 */
function isArbitraryFloat(arbitraryFloat) {
    return /^(-\d{1,15}|\d{1,15})$|^(-\d{1,15}|\d{1,15})\.\d{1,6}$/.test(arbitraryFloat);
}

/**
 * ip正则校验
 * @param ip
 * @returns {boolean}
 */
function isIp(ip) {
    return /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/.test(ip)
}

/**
 * 正则校验 金钱校验
 * @param negativeFloat
 * @returns {boolean}
 */
function isMoney(negativeFloat) {
    return /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(negativeFloat);
}

function isPasswordDengBao3(str) {
    return /\d+/.test(str) && /[a-zA-Z]+/.test(str) && /[^\w]+/.test(str);
}

/*
日期格式正则
 */
function isDate(str) {
    return /^\d{4}-\d{1,2}-\d{1,2}( \d{2}:\d{2}(:\d{2})?)?/.test(str);
}


/*
社会统一信用代码
 */
function isShtyxydm(str) {
    return /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/.test(str);
}

function isTel(str) {
    return /^(0[0-9]{2,3}-)([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/.test(str);
}

function isLng(lng) {
    return /^([-])?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/.test(lng);
}

function isLat(lat) {
    return /^([-])?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/.test(lat);
}

export function isIDCard(idCard) {
    let flag = false;
    if (idCard.length === 15) {
        let reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
        flag = reg.test(idCard)
    } else {
        let weightFactor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
        // 校验码
        let checkCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']

        let code = idCard + ''
        let last = idCard[17]// 最后一位

        let seventeen = code.substring(0, 17)

        // ISO 7064:1983.MOD 11-2
        // 判断最后一位校验码是否正确
        let arr = seventeen.split('')
        let len = arr.length
        let num = 0
        for (let i = 0; i < len; i++) {
            num = num + arr[i] * weightFactor[i]
        }

        // 获取余数
        let resisue = num % 11
        let lastNo = checkCode[resisue]

        // 格式的正则
        // 正则思路
        let idcardPatter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/

        // 判断格式是否正确
        let format = idcardPatter.test(idCard)

        // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
        flag = !!(last === lastNo && format)
    }
    return flag
}

function isSeqno(str, n) {
    let exp = new RegExp("[0-9]{" + n + "}");
    return exp.test(str)
}

function isStrS(str) {
    return /^([a-zA-Z\u4e00-\u9fa5]\|?)+[a-zA-Z\u4e00-\u9fa5]+$/.test(str)
}

function isStrByComma(str) {
    return /^([a-zA-Z\u4e00-\u9fa5]，?)+[a-zA-Z\u4e00-\u9fa5]+$/.test(str)
}

/**
 * 整数校验
 * @param percentage
 * @returns {boolean}
 */
function isZhengShu(bh) {
    return /^-?\d+$/.test(bh);
}

export function required(rule, value, callback, source, options) {
    //string、number、boolean、method、regexp、integer、float、array、object、enum、date、url、hex、email、any
    if (!isValid(rule.type, value)) {
        callback(new Error('必填项'));
    } else {
        callback();
    }
}

export function buildRequired(type = 'string', trigger = 'blur', message = '必填项') {
    let validator = function required(rule, value, callback, source, options) {
        //string、number、boolean、method、regexp、integer、float、array、object、enum、date、url、hex、email、any
        if (!isValid(rule.type, value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type, trigger, required: true, validator: validator};
}

export function buildRange(min = 0, max, type = 'string', trigger = 'blur') {
    if (!isNumber(min) || !isNumber(max) || min < 0 || min > max) {
        throw new Error("illegal range args!");
    }
    let validator = function (rule, value, callback, source, options) {
        if (rule.type === 'string' && (value && (value.length < min || value.length > max))) {
            callback(new Error(`长度在 ${min} 到 ${max} 个字符`));
        } else if (rule.type === 'number' && (isNumber(value) && (value < min || value > max))) {
            callback(new Error(`最小值${min}, 最大值${max}`));
        } else {
            callback();
        }
    };
    return {type, validator, trigger};
}

export function buildNumber(trigger = 'blur', message = '数字格式不正确') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isNumber(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'number', validator, trigger};
}

export function buildPositiveZeroTwo(trigger = 'blur', message = '请输入一个0-1之间的,小数点后有两位的数') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isPositiveZeroTwo(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'number', validator, trigger};
}

/**
 * 邮箱格式校验
 * @param trigger
 * @param message
 * @returns {{validator: validator, trigger: string, type: string}}
 */
export function buildEmail(trigger = 'blur', message = '邮箱格式错误') {
    let validator = function (rule, value, callback, source, options) {
        if (!validEmail(value) && !isBlank(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}

/**
 * 手机号校验
 * @param trigger
 * @param message
 * @returns {{validator: validator, trigger: string, type: string}}
 */
export function buildMobilePhone(trigger = 'blur', message = '手机号格式错误') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !validMobilePhone(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}

/**
 * 电话传真校验
 * @param trigger
 * @param message
 * @returns {{validator: validator, trigger: string, type: string}}
 */
export function buildPhone(trigger = 'blur', message = '手机号或电话传真格式错误') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !validPhone(value) && !validMobilePhone(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}

/**
 * 银行卡号校验
 * @param trigger
 * @param message
 * @returns {{validator: validator, trigger: string, type: string}}
 */
export function buildBankCard(trigger = 'blur', message = '银行卡号格式错误') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!validBankCard(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}

/**
 * 邮编校验
 * @param trigger
 * @param message
 * @returns {{validator: validator, trigger: string, type: string}}
 */
export function buildPostalCode(trigger = 'blur', message = '邮编格式错误') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!validPostalCode(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}

/**
 * 网址校验
 * @param trigger
 * @param message
 * @returns {{validator: validator, trigger: string, type: string}}
 */
export function buildUrl(trigger = 'blur', message = '网址格式不正确') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !validURL(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}

export function buildMax(max, type = 'number', trigger = 'blur') {
    let validator = function (rule, value, callback, source, options) {
        let max = rule.max;
        if (rule.type === 'number' && isNumber(value) && value > max) {
            callback(new Error(`最大值${max}`));
        } else if (rule.type === 'string' && value && value.length > max) {
            callback(new Error(`长度最大不可超过${max}个字符`));
        } else {
            callback();
        }
    };
    return {type, max, validator, trigger};
}

export function buildMin(min, type = 'number', trigger = 'blur') {
    let validator = function (rule, value, callback, source, options) {
        let min = rule.min;
        if (rule.type === 'number' && isNumber(value) && value < min) {
            callback(new Error(`最小值${min}`));
        } else if (rule.type === 'string' && value && value.length < min) {
            callback(new Error(`长度至少为${min}个字符`));
        } else {
            callback();
        }
    };
    return {type, min, validator, trigger};
}

export function buildRegex(pattern, msg = '格式不正确', type = 'string', trigger = 'blur') {
    if (!pattern) {
        throw new Error('pattern must be a correct regex pattern');
    }
    let validator = function (rule, value, callback, source, options) {
        if (pattern.test(value)) {
            callback();
        } else {
            callback(new Error(msg));
        }
    };
    return {type, validator, trigger};
}

export function buildPercentage(trigger = 'blur', message = '请输入正确的百分数') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !isPercentage(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'number', validator, trigger};
}

export function buildPositiveFloat(trigger = 'blur', message = '请输入整数位小于15位，小数位小于6位的正数') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !isPositiveFloat(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'number', validator, trigger};
}

export function buildNegativeFloat(trigger = 'blur', message = '请输入整数位小于15位，小数位小于6位的负数') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isNegativeFloat(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'number', validator, trigger};
}

export function buildDigits(trigger = 'blur', message = '请输入有效的正整数') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isDigits(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'number', validator, trigger};
}

export function buildArbitraryFloat(trigger = 'blur', message = '请输入整数位小于15位，小数位小于6位的实数') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isArbitraryFloat(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'number', validator, trigger};
}

export function buildIP(trigger = 'blur', message = '请输入正确的IP') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isIp(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'number', validator, trigger};
}

export function buildMoney(trigger = 'blur', message = '格式错误,最大两位小数') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isMoney(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}

/**
 * 合并"行验证器"到一个普通的验证器中，先进行普通的验证，当普通validator验证成功时进行rowValidator的验证，普通validator验证失败则不会进行rowValidator验证
 *
 * 用于在动态加行组件中实现每行不同的校验规则
 * @param rule  普通的rule对象:{type, validator, trigger}
 * @param rowValidator  行验证函数，函数入参依次为value, RowModel, RowIndex, callback, options
 */
export function combineRowValidator(rule, rowValidator) {
    const originalValidator = rule.validator;
    const validatorWrapper = function (rule, value, callback, source, options) {
        //包装callback
        const delegateCallback = (message) => {
            if (message instanceof Error) {
                callback(message);
            } else {
                const {rowModel, rowIndex} = source;
                rowValidator(value, rowModel, rowIndex, callback, options);
            }
        };
        originalValidator.call(rule, rule, value, delegateCallback, source, options);
    };
    return {...rule, validator: validatorWrapper};
}

export function buildPassword(trigger = 'blur', message = '密码至少包含一个数字，一个字母和一个特殊符号') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isPasswordDengBao3(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}

export function buildShuiHao(trigger = 'blur', message = '税号输入错误!') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!validShuiHao(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}

export function buildShtyxydm(trigger = 'blur', message = '社会统一信用代码输入错误!') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isShtyxydm(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}

export function buildKhdm(trigger = ['blur', 'change', 'input'], message = '客户代码错误，请输入正确的社会统一信信用代码或身份证号!') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && ((!isShtyxydm(value)) && !isIDCard(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}


export function buildDate(trigger = 'blur', message = '日期格式错误') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !isDate(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}

export function buildTel(trigger = 'blur', message = '电话或传真号码错误,格式为0000-12345678') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !isTel(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'string', validator, trigger};
}

export function buildIntZS0(trigger = 'blur', message = '请填写 0 ~ 2147483647 的0或正整数') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !isInt_ZS_0(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    }
    return {type: 'number', validator, trigger};
}

export function buildLng(trigger = 'blur', message = '请填写中国境内经度信息，整数位 73~135，小数位最多6位') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isLng(value) || (parseFloat(value) < 73 || parseFloat(value) >= 136))) {
            callback(new Error(message));
        } else {
            callback();
        }
    }
    return {type: 'number', validator, trigger};
}

export function buildLat(trigger = 'blur', message = '请填写中国境内纬度信息，整数位 3~53，小数位最多6位') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isLat(value) || (parseFloat(value) < 3 || parseFloat(value) >= 54))) {
            callback(new Error(message));
        } else {
            callback();
        }
    }
    return {type: 'number', validator, trigger};
}

export function buildPositiveFloatNM(n = 22, m = 6, trigger = 'blur', message = '请填写整数最多{n}位，小数最多{m}位的正数') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isPositiveFloatNM(value, n, m) || parseFloat(value) <= 0)) {
            callback(new Error(message.replace("{n}", n).replace("{m}", m)));
        } else {
            callback();
        }
    }
    return {type: 'number', validator, trigger};
}

export function buildPositiveFloatNM2(n = 22, m = 6, trigger = 'blur', message = '请填写整数最多{n}位，小数最多{m}位的正实数') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isPositiveFloatNM(value, n, m) || parseFloat(value) < 0)) {
            callback(new Error(message.replace("{n}", n).replace("{m}", m)));
        } else {
            callback();
        }
    }
    return {type: 'number', validator, trigger};
}

export function buildArbitraryFloatNM(n = 22, m = 6, trigger = 'blur', message = '请填写整数最多{n}位，小数最多{m}位的数字') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !isArbitraryFloatNM(value, n, m)) {
            callback(new Error(message.replace("{n}", n).replace("{m}", m)));
        } else {
            callback();
        }
    }
    return {type: 'number', validator, trigger};
}

export function buildIDCard(trigger = 'blur', message = '请填写正确的身份证信息') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !isIDCard(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    }
    return {type: 'string', validator, trigger};
}

export function buildSeqno(n = 3, trigger = 'blur', message = '请填写正确的流水码，不足{n}位的，前面补0') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !isSeqno(value, n)) {
            callback(new Error(message.replace("{n}", n)));
        } else {
            callback();
        }
    }
    return {type: 'string', validator, trigger};
}

export function buildMaxTarget(base, formName, target, message, trigger = 'blur') {
    let validator = function (rule, value, callback, source, options) {
        if (isBlank(value) || isBlank(base[formName][target])) {
            callback();
        } else if (parseFloat(value) > parseFloat(base[formName][target])) {
            callback(new Error(message.replace("{m}", base[formName][target])));
        } else {
            callback();
        }
    }
    return {type: 'number', validator, trigger};
}

export function buildMinTarget(base, formName, target, message, trigger = 'blur') {
    let validator = function (rule, value, callback, source, options) {
        if (isBlank(value) || isBlank(base[formName][target])) {
            callback();
        } else if (parseFloat(value) < parseFloat(base[formName][target])) {
            callback(new Error(message));
        } else {
            callback();
        }
    }
    return {type: 'number', validator, trigger};
}

export function buildIsStrS(trigger = 'blur', message = '仅能输入英文、汉字和“|”') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !isStrS(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    }
    return {type: 'string', validator, trigger};
}

export function buildStrCutByComma(trigger = 'blur', message = '仅能输入英文、汉字和“，”') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !isStrByComma(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    }
    return {type: 'string', validator, trigger};
}

export function buildBh(trigger = 'blur', message = '仅能输入英文和数字') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !isBh(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    }
    return {type: 'string', validator, trigger};
}

export function buildZmxhxsz(trigger = 'blur', message = '仅能输入英文、数字和下划线'){
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && !isZmxhxsz(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    }
    return {type: 'string', validator, trigger};
}

export function buildZs(trigger = 'blur', message = '仅能输入整数') {
    let validator = function (rule, value, callback, source, options) {
        if (!isZhengShu(value)) {
            callback(new Error(message));
        } else {
            callback();
        }
    }
    return {type: 'string', validator, trigger};
}

export function buildPositiveEightTwo(trigger = 'blur', message = '请输入整数位小于8位，小数位小于2位的正数') {
    let validator = function (rule, value, callback, source, options) {
        if (!isBlank(value) && (!isPositiveEightTwo(value))) {
            callback(new Error(message));
        } else {
            callback();
        }
    };
    return {type: 'number', validator, trigger};
}


export const ruleBuilder = {
    range: buildRange,
    required: buildRequired,
    number: buildNumber,
    max: buildMax,
    min: buildMin,
    regex: buildRegex,
    email: buildEmail,//邮箱
    phone: buildPhone,//电话加手机
    mobilePhone: buildMobilePhone,//手机号
    bankCard: buildBankCard,//银行卡
    url: buildUrl,//url
    postalCode: buildPostalCode,//邮编
    percentage: buildPercentage, // 正百分数
    positiveFloat: buildPositiveFloat,//正数  整数位 1-15位 小数位 0-6位
    negativeFloat: buildNegativeFloat,//负数  整数位 1-15位 小数位 0-6位
    arbitraryFloat: buildArbitraryFloat,//实数  整数位 1-15位 小数位 0-6位
    arbitraryFloatNM: buildPositiveFloatNM2,//实数  整数位 1-15位 小数位 0-6位
    positiveEightTwo: buildPositiveEightTwo, //正数  整数位 1-8位   小数位0-2位
    positiveZeroTwo: buildPositiveZeroTwo, //0-1 之间 精度为2的小数

    digits: buildDigits,//正整数
    ip: buildIP,//ip校验
    money: buildMoney,
    password: buildPassword,
    shuiHao: buildShuiHao,//税号
    shtyxydm: buildShtyxydm,//社会统一信用代码
    date: buildDate, //日期格式错误
    tel: buildTel, // 电话号码和传真
    intZS0: buildIntZS0, // 0~2147483647
    lng: buildLng, // 经度校验
    lat: buildLat, //维度校验
    positiveFloatNM: buildPositiveFloatNM, // n m 数字位数校验 正数
    ArbitraryFloatNM: buildArbitraryFloatNM,  // n m 数字位数校验 负数
    idCard: buildIDCard, //身份证信息
    seqno: buildSeqno, // 流水号验证, n
    maxTarget: buildMaxTarget, // 目标最大值限定校验
    minTarget: buildMinTarget, // 目标最小值限定校验
    isStrS: buildIsStrS, // 字母、中文 和 “|”
    strCutByComma: buildStrCutByComma ,// 字母、中文 和 “，”
    bh:buildBh,//字母和数字
    zmxhxsz:buildZmxhxsz, //字母下划线和数字
    zhengshu: buildZs, // 整数
    khdm: buildKhdm, // 客户代码（身份证号或 与 统一社会信用代码）
};
